import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import api_image from "../../Assets/Projects/maher_api.png";
import sd_image from "../../Assets/Projects/stable_diffusion.png"
import tik_image from "../../Assets/Projects/tiktok.png"
import x_image from "../../Assets/Projects/x.png"
import gpt_image from "../../Assets/Projects/chatgpt.png"
import code_image from "../../Assets/Projects/code-syntax.io.png"


function Projects() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading bold-heading">
          My Recent Works
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={api_image}
              isBlog={false}
              title="NexOracle Rest APIs"
              description="Your go-to solution for robust and reliable APIs, designed to streamline development and elevate your projects. With easy-to-use endpoints, scalable performance, and affordable pricing, Maher APIs provide developers with the tools they need to build, innovate, and succeed."
              ghLink="https://github.com/nexoracle/rest-api"
              demoLink="https://api.nexoracle.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sd_image}
              isBlog={false}
              title="Stable Diffusion"
              description="Developed a custom Stable Diffusion image generator, leveraging advanced deep learning techniques to create high-quality, AI-driven images. The model allows for fine-tuned control over artistic styles and compositions, delivering stunning visuals tailored to specific needs."
              demoLink="https://sd.maher-zubair.tech"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gpt_image}
              isBlog={false}
              title="ChatGPT 4"
              description="A ChatGPT clone offering a seamless conversational experience with features like saving chat history, a toggle theme for light or dark mode, a quick chat delete button, and fast response times—all completely free to use."
              demoLink="/gpt/gpt4.html"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={code_image}
              isBlog={false}
              title="Code-Syntax.io"
              description="A versatile tool for web development and documentation. Edit HTML, CSS, and JavaScript with live preview, create professional README files using a custom Markdown toolbar, and saves your work in local storage for easy access."
              demoLink="https://codesyntaxio.maher-zubair.xyz"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tik_image}
              isBlog={false}
              title="Tiktok Downloader"
              description="A versatile TikTok downloader that allows you to download videos with or without watermarks, as well as extract and download audio separately. This tool ensures quick and easy access to your favorite TikTok content in the format you prefer."
              demoLink="tiktok/index.html"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={x_image}
              isBlog={false}
              title="X Downloader"
              description="A powerful X downloader that lets you save videos directly from X with ease. This tool provides a simple way to download your favorite X media in high quality, so you can enjoy and share it offline anytime."
              demoLink="twitter/index.html"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
