import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }} className="home-about-intro">
              LET ME INTRODUCE MYSELF
            </h1>
            <p className="home-about-body">
              I fell in love with programming and Coding is my Hobby.
              <br />
              <br />
              My field of Interest's are building new &nbsp;
                <b className="purple">Web Technologies and Products.</b>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              &nbsp; like
                <b className="purple"> React.js and Next.js</b>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            {/* <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt> */}
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1 className="find-me">FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
            <li className="social-icons">
                <a
                  href="mailto:contact@maher-zubair.xyz"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons mail-icon"
                >
                  <RiMailSendLine />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/Maher-Zubair"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons github-icon"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/maher-zubair"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons linkedin-icon"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/maher_xubair"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons insta-icon"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
