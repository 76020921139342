import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { RiMailSendLine } from "react-icons/ri";

function Footer() {
  let year = 2024;
  return (
    <Container fluid className="footer">
      <Row>
<Col md="4" className="footer-copywright">
  <h3>Designed and Developed by <a href="https://nexoracle.com" target="_blank" rel="noopener noreferrer">NexOracle</a></h3>
</Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} Maher Zubair</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
          <li className="social-icons">
              <a
                href="mailto:contact@maher-zubair.xyz"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <RiMailSendLine />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://github.com/Maher-Zubair"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/maher-zubair"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/maher_xubair"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
