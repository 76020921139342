import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";
import {
  SiRedis,
  SiPostgresql,
  SiExpress,
  SiMui,
  SiVite,
  SiNextdotjs
} from "react-icons/si";
import { 
  FaHtml5,
  FaCss3,
  FaGithub,
  FaBootstrap
} from "react-icons/fa";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons" title="MongoDB">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Express">
        <SiExpress />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="React">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Node.js">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Html5">
        <FaHtml5 />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Css3">
        <FaCss3 />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Javascript">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Python">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Bootstrap">
        <FaBootstrap />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Material UI">
        <SiMui />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Vite">
        <SiVite />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Nextjs">
        <SiNextdotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Redis">
        <SiRedis />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Postgre sql">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons" title="Git">
        <DiGit />
      </Col>
    </Row>
  );
}

export default Techstack;
